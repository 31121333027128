<template>
<CRow>
  <CCol sm="12">           
    <CCard>
        <CCardHeader class="pt-2 pb-0 d-flex flex-row">    
            <CIcon name="cil-truck" size="lg" class="mr-2" /><h5 class="">Bonus Shipments</h5> 
        </CCardHeader>
        <CCardBody class="pt-0" v-if="canView">
          <ShipmentFilter 
            :total="totalItems"
            @pageSizeChange="onPageSizeChange"
            @filterChange="onFilterChange"
            @export="onExport"
          />
          <CDataTable            
            :fields="fields"
            :loading="loading"
            :items="items"
            :sorter='{resetable: false}'
            :sorterValue='sorterValue'
            @update:sorter-value="onSortChanged"
          >   
          <template #select-header>
            <CButton v-if="canEdit"
            @click="onMarkSelectedShipped"
            color="primary" class="m-0 p-1" style="width: 40px" title="Mark selected as shipped"><CIcon name="cil-truck" /></CButton>
          </template>
          <template #select="{item}">
            <td>
              <CInputCheckbox
                :checked="item._selected"
                @update:checked="() => check(item)"
                custom
              />
            </td>
          </template>
            <template #dateUtc="{item}">
              <td class="text-nowrap pl-0">{{formatDate(item.dateUtc)}}
              </td>
            </template>
            <template #orderNo="{item}">
              <td>
                <span class="text-nowrap strong strong">{{item.orderNo}}</span><br />
                <span class="text-nowrap text-info">{{item.channel}}</span>
              </td>
            </template>
            <template #bonusSKU="{item}">
              <td>
                <span v-if="item.bonusSKU" class="strong text-info">{{item.bonusSKU}}</span>
                <span v-else class="text-danger"><CIcon name="cil-minus" color="danger" /> not selected</span>
              </td>
            </template>
            <template #address="{item}">
                <td>
                  <Address v-if="item.address" :address="item.address" :countryName="item.countryName" />                    
                </td>
            </template>
            <template #email="{item}">
              <td>
                <a v-if="item.email" :href="`mailto:${item.email}`"><CIcon size="sm" name="cil-envelope-closed" class="mb-2 mr-1" style="transform: translateY(1px)"/>{{item.email}}</a>
                <CIcon v-if="item.optIn" name="cil-check-circle" class=" mt-0 ml-1 text-success" title="Opt In" style="transform: translateY(-1px)" />
                <template v-if="item.phone">
                  <br />
                  <a :href="`tel:${item.phone}`"><CIcon size="sm" name="cil-phone" class="mb-2 mr-1" style="transform: translateY(2px)"/>{{item.phone}}</a>
                </template>
              </td>
            </template>
            <template #shipped="{item}">
              <td>
                <h5 v-if="item.shipped == null" class="text-warning strong p-0 m-0 pr-2 inline mt-1" style="transform: translateY(3px)">?</h5>
                <CIcon  v-if="item.shipped == true"  class="text-success mr-2" name="cil-check-alt" size="lg" />
                <CIcon  v-if="item.shipped == false" class="text-danger mr-2" name="cil-minus" size="lg" />
                <CButton v-if="!item.shipped && canEdit" 
                 @click="onMarkShipped(item.id)"
                  color="primary" class="m-0 px-2 py-0 mr-1" v-c-tooltip='{content: "Mark as shipped"}' 
                ><CIcon name="cil-truck" /></CButton>
                <CButton v-if="(item.shipped || item.shipped == null) && canEdit" 
                        @click="onMarkUnshipped(item.id)"
                         color="danger" 
                         class="m-0 px-1 py-0 mb-0" v-c-tooltip='{content: "Mark as not shipped"}'
                  ><CIcon name="cil-x" /></CButton>
                <template v-if="item.shippedDate"><br /><small>{{formatDate(item.shippedDate)}}</small></template>
              </td>
            </template>
            <template #comment="{item}">
              <td class="d-flex">
                <CAlert v-if="item.comment"   
                      :ref="`comment_${item.id}`"           
                      color="warning" 
                      class="p-1 px-2 comment-box inline mr-2" :title="item.comment"                      
                  >
                  <div v-on:click="onCommentClick(item.comment, $refs[`comment_${item.id}`])" style="cursor: pointer">
                    <small>{{item.comment}}</small>
                  </div>
                </CAlert>
                <a v-if="canEdit" 
                  @click="onEditComment(item)">
                  <CIcon name="cil-pencil" class="pt-0 mt-2 cursor-pointer text-primary" />
                </a>
              </td>
            </template>
          </CDataTable>
          <CPagination
            :pages="pages"
            :active-page.sync="activePage"            
          />
          <CAlert v-if="floatComment"
                ref="floatingComment"                
                :closeButton="true" 
                @update:show="onShowComment"
                :show="!!floatComment"
                color="warning" 
                class="p-2 pr-3 shadow" 
                style="position: fixed; z-index: 2000; max-width: 500px"
          >
            <small class="ml-1 mt-2 mr-2 pr-3" style="display: block; white-space: pre-line;">{{floatComment}}</small>
          </CAlert>
          <CommentModal ref="commentModal" @saved="onCommentSaved" />   
        </CCardBody>
    </CCard>   
  </CCol>
</CRow>
</template>
<script>
import Address from '@/components/Address'
import ShipmentFilter from '@/components/ShipmentFilter'
import CommentModal from '@/components/modals/CommentModal'
import { shipments as shipmentsApi } from '@/api/';
import { formatDate } from '@/utils';
import { mapGetters, mapMutations } from 'vuex';
import { LOADING } from '@/store/modules/shipments'
import { EDIT_SHIPMENT, VIEW_SHIPMENT, mapPermissions } from '@/permissions'

export default {
  components: {
    Address,
    ShipmentFilter,
    CommentModal
  },
  data() {
    return { 
      items: null,
      floatComment: null,
      loading: false,
      itemsPerPage: 10,
      totalItems: 0,
      activePage: 1,
      pages: 1,
      sort: 'descending',
      filter: null
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'shipments/' + LOADING
    }),
    getFilter() {
      let dateFrom = this.filter?.dateFrom;
      let dateUntil = this.filter?.dateUntil;

      const filter = { 
        ...this.filter,
        dateFrom: dateFrom? dateFrom.toISOString() : null,
        dateUntil: dateUntil ? dateUntil.toISOString() : null,
        itemsPerPage: this.itemsPerPage, 
        pageIndex: this.activePage - 1,
        dateSort: this.sort
      };

      return filter;
    },
    async loadShipments() {
      this.loading = true;      
      const filter = this.getFilter();

      let response = null;      
      try {         
        response = await shipmentsApi.find({merchantId: this.merchantId, filter });
      }
      catch(e) {        
        this.loading = false;
        return;
      }

      this.loading = false;      
      this.items = response.data.shipments;
      const total = response.data.total;
      this.totalItems = total;

      this.pages = Math.ceil(total / this.itemsPerPage);

      this.onHideComment();
    },
    async markShipped(shipped, idList) {
      this.setLoading(true);
      try {
          await shipmentsApi.markShipped({merchantId:this.merchantId,idList,shipped});
      }
      catch(e) {
          this.setLoading(false);
      }
      this.setLoading(false);

      this.onHideComment();
    },    
    getTooltip(m) {
      return {
        content: m,
        html: false,
        popperOptions: {modifiers: {applyStyles: {backgroundColor: 'black'} }}
      }
    },
    formatDate: (dateStr) => formatDate(dateStr),
    onPageSizeChange(val) {
      this.itemsPerPage = val;
      this.activePage = 1;
      this.loadShipments();
    },
    onSortChanged(val) {
      this.sort = val.asc ? "ascending" : "descending";
      this.loadShipments();
    },
    onFilterChange(val) {
      this.filter = val;
      this.activePage = 1;
      this.loadShipments();
    },    
    async onExport() {
      const filter = this.getFilter();
      this.setLoading(true);
      let response = null;
      try {
          response = await shipmentsApi.export({merchantId: this.merchantId, filter });

          const blob = new Blob([response.data], { type: 'application/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "shipments.csv";
          link.click()
          URL.revokeObjectURL(link.href)
      }
      catch(e) {
          this.setLoading(false);
      }
      this.setLoading(false);
    },
    async onMarkShipped(id)
    {
      await this.markShipped(true, [id]);
      await this.loadShipments();
    },
    async onMarkUnshipped(id)
    {
      await this.markShipped(false, [id]);
      await this.loadShipments();
    },
    async onMarkSelectedShipped() {
      if (this.items && this.items.length > 0) {
        const selected = this.items.filter(o => o._selected);
        if (selected.length > 0) {
          await this.markShipped(true, selected.map(o => o.id));
          await this.loadShipments();
        }
      }
    },
    onEditComment(item) {
      this.$refs.commentModal.edit(item);
    },
    onCommentSaved() {
      this.loadShipments();
    },
    onCommentClick(comment, theRef) {
      const rect = theRef.$el.getBoundingClientRect();
      const o = this;
      setTimeout(function() {
        const floatRef = o.$refs.floatingComment;
        const right = Math.floor(window.innerWidth - rect.right);
        const top = Math.floor(rect.top - 10);
        floatRef.$el.style["right"] = right + "px";
        floatRef.$el.style["top"] = top + "px";
      }, 50);
      //
      this.floatComment = comment;
    },
    onHideComment() {
      this.onShowComment(false);
    },
    onShowComment(val) {
      if (!val) {
        this.floatComment = null;
      }
    },
    check (item) {
      const val = Boolean(item._selected)
      this.$set(item, '_selected', !val);
    },
    onScroll() {
      this.floatComment = null;
    }
  },
  computed: {
    ...mapPermissions({
      canEdit: EDIT_SHIPMENT,
      canView: VIEW_SHIPMENT
    }),
    ...mapGetters({
      hasRight: 'hasRight',
      merchantId: 'merchantId'
    }),
    sorterValue() {
      const sorter = { 
        column: 'dateUtc', 
        //direction: "desc", 
        asc: this.sort == 'ascending', 
        desc: this.sort == 'descending'
      }
      return sorter;
    },
    fields() {
      return [
         { 
          key: 'select', 
          label: '', 
          _style: 'min-width:1%; padding: 0px !important', 
          _classes: 'p-0',
          sorter: false,
          filter: false
        },
        { label: 'Date', key: 'dateUtc', sorter: true, _classes: 'pl-0', },
        { label: 'Order', key: 'orderNo', sorter: false, _style: 'width: auto' },
        { label: 'Reward', key: 'bonusSKU', sorter: false },
        { label: 'Name / Address',  key: 'address', sorter: false },
        { label: 'E-Mail / Phone', key: 'email', sorter: false },
        { label: 'Shipped', key:'shipped', sorter: false, _style:"max-width: 110px; min-width: 110px" },
        { label: 'Comment', key:'comment', sorter: false },
      ];
    }
  },
  watch: {
    activePage() {
      this.loadShipments();
    }
  },
  created() {
    this.loadShipments();
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  }
}
</script>
<style scoped>
.comment-box {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 120px; 
  max-height: 34px;
  display: inline-block;   
}
</style>
