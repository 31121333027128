<template>
    <address class="mb-0 pb-0">
        <strong class="text-nowrap">{{fullname}}</strong><br />
        <small>{{line1}}</small><br />
        <small v-if="line2" class="text-nowrap">{{line2}}<br /></small>
        <small v-if="line3" class="text-nowrap">{{line3}}<br /></small>
        <template v-if="isUsAddress">
            <small class="text-nowrap">{{address.city}} {{address.state}} {{address.postcode}}</small><br />
            <small>{{countryName}}</small>
        </template>
        <template v-else>
            <small class="text-nowrap">{{address.postcode}} {{address.city}}</small><br />
            <small>{{countryName}}</small>
        </template>
    </address>
</template>
<script>
export default {
    props: {
        address: Object,
        countryName: String
    },
    computed: {
        fullname() {
            return this.address.fullname;
        },
        isUsAddress() {
            return ['US', 'CA'].includes(this.address.country);
        },
        line1()  { return this.address.addressLine1; },
        line2()  { return this.address.addressLine2; },
        line3()  { return this.address.addressLine3; }
    }
}
</script>
