<template>
<div class="mt-1 mb-2">
    <CRow class="my-0 mx-0 mb-2 pr-0 d-flex">
        <CSelect 
            class="mr-2 mt-1 no-wrap my-0 form-group form-row filter-group px-0" 
            addLabelClasses="strong text-nowrap date-label-col mx-0 col-form-label col-sm-3 pr-0"
            :addInputClasses="{'text-warning': isChannelDirty, 'col ml-1 mr-1': true}"
            :options="channels"
            :value.sync="channel"
            label="Channel" 
        />
        <CSelect 
            class="mr-2 mt-1 no-wrap my-0 form-group form-row filter-group px-0" 
            addLabelClasses="strong text-nowrap date-label-col col-form-label col-sm-3 pl-1 pr-0"
            :addInputClasses="{'text-warning': isBonusDirty, 'col ml-1 mr-1': true}"
            :options="bonuses"
            :value.sync="bonus"
            label="Bonus" 
        />
        <CSelect 
            class="mr-2 mt-1 no-wrap my-0 form-group form-row filter-group px-0" 
            addLabelClasses="strong text-nowrap date-label-col mx-0 col-form-label col-sm-3 pr-0"
            :addInputClasses="{'text-warning': isShippedDirty, 'col ml-1 mr-1': true}"
            :options="shippedOptions"
            :value.sync="shipped"
            label="Shipped" 
        />
        <CInput
            class="mr-2 mt-1 no-wrap my-0 filter-group px-0 my-0"
            addLabelClasses="strong text-nowrap date-label-col mx-0 pr-0"            
            :addInputClasses="{'text-warning': isDateFromDirty}"
            label="From"
            type="date"            
            horizontal
            :value="dateFrom"
            :is-valid="isDateFromValid"
            @update:value="onDateFromChanged"
        />
        <CInput
            class="mr-2 mt-1 no-wrap my-0 filter-group px-0 my-0"
            addLabelClasses="strong text-nowrap date-label-col pr-0"
            :addInputClasses="{'text-warning': isDateUntilDirty}"
            label="Until"
            type="date"            
            horizontal
            :value="dateUntil"
            :is-valid="isDateUntilValid"
            @update:value="onDateUntilChanged"
        />
        <CSelect 
            class="mr-2 mt-1 no-wrap my-0 form-group form-row filter-group px-0" 
            addLabelClasses="strong text-nowrap date-label-col mx-0 col-form-label col-sm-3 pr-0"
            :addInputClasses="{'text-warning': isCountryDirty, 'col ml-1 mr-1': true}"
            :options="countries"
            :value.sync="country"
            label="Country" 
        />
    </CRow>
    <CRow class="my-0 mx-0">        
        <div class="col form-inline mr-0 pr-0 mr-0 pl-0 filter-group">
            <CButton color="success" class="ml-0 pr-3 filter-btn"
                @click="onFindClick"
            ><CIcon name="cil-search" class="mr-2 my-0 btn-icon" />Filter</CButton>  
            <CButton color="secondary" class="ml-2 pr-3 filter-btn"
                @click="onResetClick"
            ><CIcon name="cil-x" class="mr-2 my-0 btn-icon" />Clear</CButton>  
        </div>
        <div class="col pl-0 ml-0">
             <CButton v-if="hasRight(EXPORT_SHIPMENT)"
                :disabled="!total || total > 1000"
                 @click="onExportClick"
                color="info" 
                class="ml-0 pr-3"
             ><CIcon name="cil-cloud-download" class="mr-2 my-0 btn-icon" />Export</CButton>  
             <small v-if="total > 1000" class="ml-2 pt-0 text-danger" style="display: inline-block; transform: translateY(3px)">Too many items selected (1000 max.)</small>
        </div>
        <CCol class="col p-0">
            <div class="form-inline justify-content-sm-end">   
                <strong class="mr-3" v-if="total">Total items: {{total}}</strong>
            <CSelect 
                :options="[10,25,50,100]" 
                add-label-classes="pr-2"
                :value="itemsPerPage"
                label="Items per page"
                @update:value="onPageSizeChange"
            />
            </div>
        </CCol>
    </CRow>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOAD_SETTINGS } from '@/store/modules/shipments'
import { EXPORT_SHIPMENT, mapPermissions } from '@/permissions'

export default {
    data() {
        return {
            EXPORT_SHIPMENT,
            dateFrom: null,
            dateUntil: null,
            shipped: null,
            channel: null,
            bonus: null,
            country: null,
            itemsPerPage: 10,
            // Dirty flags
            isDateFromDirty: false,
            isDateUntilDirty: false,
            isShippedDirty: false,
            isChannelDirty: false,
            isBonusDirty: false,
            isCountryDirty: false,            
        }
    },
    props: {
        total: Number,
    },
    emits: ["filterChange", "pageSizeChange", "export"],
    methods: {
        ...mapActions({
            loadSettings: 'shipments/' + LOAD_SETTINGS
        }),
        onPageSizeChange(val) {
            this.itemsPerPage = val;
            this.$emit("pageSizeChange", val);
        },
        onDateFromChanged(val) {
            this.dateFrom = val;
            this.setIsDirty();
        },
        onDateUntilChanged(val) {
            this.dateUntil = val;
            this.setIsDirty();
        },
        onFindClick() {
            if (this.isDateRangeValid !== false) {
                const filter = this.createFilter();                
                this.$emit("filterChange", {...filter, itemsPerPage: this.itemsPerPage});
                this.activeFilter = {...filter};
                this.setIsDirty();
            }
        },
        onResetClick() {
            this.dateFrom = null;
            this.dateUntil = null;
            this.shipped = null;
            this.channel = null;
            this.bonus = null;
            this.country = null;
            
            const o = this;
            setTimeout(function() {
                o.setIsDirty();
            })            
        },
        onExportClick() {
            this.$emit("export");
        },
        // Validation
        isDateFromValid() {
            return this.isDateRangeValid;
        },
        isDateUntilValid() {
            return this.isDateRangeValid;
        },
        // reset dirty
        setIsDirty() {
            const f = this.activeFilter;
            this.isDateFromDirty = this.dateFrom != f.dateFrom;
            this.isDateUntilDirty = this.dateUntil != f.dateUntil;            
            this.isShippedDirty = this.shipped != f.shipped;
            this.isChannelDirty = this.channel != f.channel;
            this.isBonusDirty = this.bonus != f.bonus;
            this.isCountryDirty = this.country != f.country
        },
        createFilter() {
            let endDate = this.dateUntil;
            if (this.dateUntil) {
                endDate = new Date(this.dateUntil + " 00:00");
                endDate.setDate(endDate.getDate() + 1);
            }
            return {
                dateFrom:  this.dateFrom ? new Date(this.dateFrom + " 00:00") : null,
                dateUntil: endDate ? endDate : null,
                shipped:   this.shipped,
                channel:   this.channel,
                bonus:     this.bonus,
                country:   this.country
            };
        }
    },
    computed: {
        ...mapGetters({
            hasRight: 'hasRight',
            channelOptions: 'shipments/channels',
            bonuseOptions: 'shipments/bonuses',
            countryOptions: 'shipments/countries',
            loading: 'shipments/loading'
        }),
        ...mapPermissions({
            canExport:EXPORT_SHIPMENT  
        }),
        channels() {            
            return (this.channelOptions && this.channelOptions.length == 1)
                  ? this.channelOptions
                  : [{label: 'Any', value: null}, ...this.channelOptions];
        },
        bonuses() {
            return (this.bonuseOptions && this.bonuseOptions.length == 1) 
                    ? this.bonuseOptions 
                    : [{label: 'Any', value: null}, ...this.bonuseOptions];
        },
        countries() {
            return (this.countryOptions && this.countryOptions.length == 1) 
                    ? this.countryOptions 
                    : [{label: 'Any', value: null}, ...this.countryOptions];
        },
        shippedOptions: () => [{label:'All', value: null}, {label:'Shipped', value: true},{label: 'Not Shipped', value: false}],
        isDateRangeValid() {
            return (this.dateFrom && this.dateUntil && this.dateFrom > this.dateUntil) ? false : null;
        }
    },
    watch: {
        shipped() {
            this.setIsDirty();
        },
        bonus() {
            this.setIsDirty();
        },
        channel() {
            this.setIsDirty();
        }
    },
    created() {
        this.loadSettings();
        this.activeFilter = {};
    }
}
</script>

<style scoped>
.filter-group {
    max-width: 240px;
    width: 240px;
}
.filter-btn {
    width: 100px;
}
.btn-icon {
    transform: translateY(-2px);
}
</style>
<style>
.date-label-col {
  max-width:105px; 
  min-width:60px; 
  width:60px;
}
.move-y-shipped-input {
    transform: translateY(-2px);
}
</style>