var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',{staticClass:"pt-2 pb-0 d-flex flex-row"},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-truck","size":"lg"}}),_c('h5',{},[_vm._v("Bonus Shipments")])],1),(_vm.canView)?_c('CCardBody',{staticClass:"pt-0"},[_c('ShipmentFilter',{attrs:{"total":_vm.totalItems},on:{"pageSizeChange":_vm.onPageSizeChange,"filterChange":_vm.onFilterChange,"export":_vm.onExport}}),_c('CDataTable',{attrs:{"fields":_vm.fields,"loading":_vm.loading,"items":_vm.items,"sorter":{resetable: false},"sorterValue":_vm.sorterValue},on:{"update:sorter-value":_vm.onSortChanged},scopedSlots:_vm._u([{key:"select-header",fn:function(){return [(_vm.canEdit)?_c('CButton',{staticClass:"m-0 p-1",staticStyle:{"width":"40px"},attrs:{"color":"primary","title":"Mark selected as shipped"},on:{"click":_vm.onMarkSelectedShipped}},[_c('CIcon',{attrs:{"name":"cil-truck"}})],1):_vm._e()]},proxy:true},{key:"select",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInputCheckbox',{attrs:{"checked":item._selected,"custom":""},on:{"update:checked":function () { return _vm.check(item); }}})],1)]}},{key:"dateUtc",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap pl-0"},[_vm._v(_vm._s(_vm.formatDate(item.dateUtc))+" ")])]}},{key:"orderNo",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticClass:"text-nowrap strong strong"},[_vm._v(_vm._s(item.orderNo))]),_c('br'),_c('span',{staticClass:"text-nowrap text-info"},[_vm._v(_vm._s(item.channel))])])]}},{key:"bonusSKU",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.bonusSKU)?_c('span',{staticClass:"strong text-info"},[_vm._v(_vm._s(item.bonusSKU))]):_c('span',{staticClass:"text-danger"},[_c('CIcon',{attrs:{"name":"cil-minus","color":"danger"}}),_vm._v(" not selected")],1)])]}},{key:"address",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.address)?_c('Address',{attrs:{"address":item.address,"countryName":item.countryName}}):_vm._e()],1)]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.email)?_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_c('CIcon',{staticClass:"mb-2 mr-1",staticStyle:{"transform":"translateY(1px)"},attrs:{"size":"sm","name":"cil-envelope-closed"}}),_vm._v(_vm._s(item.email))],1):_vm._e(),(item.optIn)?_c('CIcon',{staticClass:" mt-0 ml-1 text-success",staticStyle:{"transform":"translateY(-1px)"},attrs:{"name":"cil-check-circle","title":"Opt In"}}):_vm._e(),(item.phone)?[_c('br'),_c('a',{attrs:{"href":("tel:" + (item.phone))}},[_c('CIcon',{staticClass:"mb-2 mr-1",staticStyle:{"transform":"translateY(2px)"},attrs:{"size":"sm","name":"cil-phone"}}),_vm._v(_vm._s(item.phone))],1)]:_vm._e()],2)]}},{key:"shipped",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.shipped == null)?_c('h5',{staticClass:"text-warning strong p-0 m-0 pr-2 inline mt-1",staticStyle:{"transform":"translateY(3px)"}},[_vm._v("?")]):_vm._e(),(item.shipped == true)?_c('CIcon',{staticClass:"text-success mr-2",attrs:{"name":"cil-check-alt","size":"lg"}}):_vm._e(),(item.shipped == false)?_c('CIcon',{staticClass:"text-danger mr-2",attrs:{"name":"cil-minus","size":"lg"}}):_vm._e(),(!item.shipped && _vm.canEdit)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: "Mark as shipped"}),expression:"{content: \"Mark as shipped\"}"}],staticClass:"m-0 px-2 py-0 mr-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.onMarkShipped(item.id)}}},[_c('CIcon',{attrs:{"name":"cil-truck"}})],1):_vm._e(),((item.shipped || item.shipped == null) && _vm.canEdit)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: "Mark as not shipped"}),expression:"{content: \"Mark as not shipped\"}"}],staticClass:"m-0 px-1 py-0 mb-0",attrs:{"color":"danger"},on:{"click":function($event){return _vm.onMarkUnshipped(item.id)}}},[_c('CIcon',{attrs:{"name":"cil-x"}})],1):_vm._e(),(item.shippedDate)?[_c('br'),_c('small',[_vm._v(_vm._s(_vm.formatDate(item.shippedDate)))])]:_vm._e()],2)]}},{key:"comment",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[(item.comment)?_c('CAlert',{ref:("comment_" + (item.id)),staticClass:"p-1 px-2 comment-box inline mr-2",attrs:{"color":"warning","title":item.comment}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onCommentClick(item.comment, _vm.$refs[("comment_" + (item.id))])}}},[_c('small',[_vm._v(_vm._s(item.comment))])])]):_vm._e(),(_vm.canEdit)?_c('a',{on:{"click":function($event){return _vm.onEditComment(item)}}},[_c('CIcon',{staticClass:"pt-0 mt-2 cursor-pointer text-primary",attrs:{"name":"cil-pencil"}})],1):_vm._e()],1)]}}],null,false,2806576434)}),_c('CPagination',{attrs:{"pages":_vm.pages,"active-page":_vm.activePage},on:{"update:activePage":function($event){_vm.activePage=$event},"update:active-page":function($event){_vm.activePage=$event}}}),(_vm.floatComment)?_c('CAlert',{ref:"floatingComment",staticClass:"p-2 pr-3 shadow",staticStyle:{"position":"fixed","z-index":"2000","max-width":"500px"},attrs:{"closeButton":true,"show":!!_vm.floatComment,"color":"warning"},on:{"update:show":_vm.onShowComment}},[_c('small',{staticClass:"ml-1 mt-2 mr-2 pr-3",staticStyle:{"display":"block","white-space":"pre-line"}},[_vm._v(_vm._s(_vm.floatComment))])]):_vm._e(),_c('CommentModal',{ref:"commentModal",on:{"saved":_vm.onCommentSaved}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }