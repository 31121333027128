<template>
<CModal
    color="warning"
    :show.sync="showModal"
    :closeOnBackdrop='false'
    addContentClasses="pb-0"
    class="pb-0"
>
<template #body-wrapper>
    <div class="modal-body pb-1">
        <CTextarea ref="textArea" 
            v-model="comment"
            class="my-0" add-input-classes="min-ht-100" maxlength="200" 
        />
        <div class="mt-1">
        <CLink class="mb-0 pb-0" @click="onClear" :disabled="!comment" :class="!!comment ? 'text-primary' : 'text-secondary'"><CIcon name="cil-x" size="sm" class=" ml-1 mr-0 mb-1" /> Clear</CLink>
        </div>
    </div>
    </template>
    <template #header>
        <h4 class="modal-title my-0">Edit Comment</h4>
    </template>
    <template #footer>
        <div v-if="loading" class="mr-auto d-flex align-items-center">
            <CSpinner class="mr-3" size="sm" color="warning" /><strong>Saving comment ...</strong>
        </div>
        <CButton :disabled='loading' @click="onCancel" class="my-0">Cancel</CButton>
        <CButton :disabled='loading' color="success" class="my-0" @click="onSave">Save Comment</CButton>
    </template>
</CModal>        
</template>

<script>
import { shipments as shipmentsApi } from '@/api/';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            showModal: false,
            loading: false,
            itemId: null,
            comment: null
        }
    },
     computed: {
        ...mapGetters({
            merchantId: 'merchantId'
        })
    },
    methods: {
        edit(item) {
            this.itemId = item.id;
            this.comment = item.comment ? item.comment : "";
            this.showModal = true;            
            const tb = this.$refs.textArea;
            setTimeout(function() {
                tb.$el.querySelector("textarea").focus();
            }, 200);
        },
        onCancel() {
            this.showModal = false;
        },
        onClear() {
            this.comment = null;
        },
        async onSave() {
            this.loading = true;
            try {
                await shipmentsApi.setComment({merchantId:this.merchantId,id:this.itemId,comment:this.comment});
            }
            catch //(e) 
            { 
                console.log('Error');
            }
            this.loading = false;

            this.showModal = false;

            this.$emit("saved");
        }
    }
}
</script>


